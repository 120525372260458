import request from './ajax'

const newsUrl = '/api/news/'
// 轮播列表
export const newsBanner = (data) => request(newsUrl + 'banner', 'post', { ...data })
// 公告列表
export const newsNotices = (data) => request(newsUrl + 'notices', 'post', { ...data })
// 公告详情
export const newsNotice = (data) => request(newsUrl + 'notice', 'post', { ...data })
// 关于我们
export const getAboutUs = (data) => request(newsUrl + 'about_us', 'post', { ...data })
