<template>
  <div>
    <div class="nav-bar">
      <van-icon
        name="arrow-left"
        color="#9B9B9B"
        class="nav-icon"
        @click="$router.go(-1)"
      />
      <span>系统消息</span>
    </div>
    <div class="page-layout">
      <div v-for="(item, i_key) in list" :key="i_key" @click="goToUrl(item.id)">
        <div class="time-box">
          <span class="time">{{ item.created_date }}</span>
        </div>
        <div class="inform-card">
          <h4>{{ item.title }}</h4>
          <p v-html="item.content"></p>
          <div class="details">
            <span>查看详情</span>
            <van-icon name="arrow" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { newsNotices } from "@/api/news";

export default {
  name: "system-inform",
  data() {
    return {
      list: [],
      page: 1,
      total: 100,
    };
  },
  mounted() {
    this.newsNotices();
  },
  methods: {
    goToUrl(id) {
      this.$router.push({ name: "inform-details", query: { id: id } });
    },
    async newsNotices() {
      if (this.list.length >= this.total) {
        return false;
      }
      const ret = await newsNotices({ page: this.page });
      if (ret.code * 1 == 1) {
        this.list = ret.data;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  text-align: center;
  height: 57px;
  line-height: 57px;
  font-size: 21px;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  .nav-icon {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
  }
}
.page-layout {
  padding: 46px 12px 0 12px;
  .time-box {
    margin: 13px 0;
    text-align: center;
  }
  .time {
    text-align: center;
    padding: 4px 10px;
    background: #ffffff;
    opacity: 0.49;
    border-radius: 14px;
    color: #aaaaaa;
    margin: 13px 0;
  }
  .inform-card {
    background-color: #fff;
    border-radius: 16px;
    padding: 12px;
    font-size: 14px;
    h4 {
      font-size: 18px;
      font-weight: normal;
      margin: 0;
    }
    p {
      margin: 0;
      border-bottom: 1px solid #f2f2f2;
      font-size: 14px;
      color: #6f6f6f;
    }
    .details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      font-size: 16px;
    }
  }
}
</style>
